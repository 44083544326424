import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    Show,
    SimpleForm,
    NumberField, TextInput, NumberInput
} from 'react-admin';
import {Grid} from "@material-ui/core";
import moment from "moment";
import CodeEditor from "../UI/CodeEditor";

export const FundingParameterList = props => (
    <List {...props}>
        <Datagrid>
            <NumberField label="id" source="_id" />
            <NumberField label="Stimmenziel" source="threshold" />
            <NumberField label="Förderungsbetrag" source="increment" />
            <EditButton />
        </Datagrid>
    </List>
);

export const FundingParameterShow = props => (
    <Show {...props}>
        <SimpleForm>
        </SimpleForm>
    </Show>
);

// PATCH endpoint
export const FundingParameterEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput disabled fullWidth label="ID" source="_id"/>
                </Grid>
                <Grid item xs={12}>
                    <NumberInput fullWidth label="Threshold" source="threshold"/>
                </Grid>
                <Grid item xs={12}>
                    <NumberInput fullWidth label="Increment" source="increment"/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

// POST endpoint
export const FundingParameterCreate = props => (
    <Create {...props}>
        <SimpleForm>
        </SimpleForm>
    </Create>
);
