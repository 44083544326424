import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    downloadCSV,
    Edit,
    EditButton,
    EmailField,
    FormTab,
    ImageField,
    ImageInput,
    FunctionField,
    List,
    Pagination,
    ReferenceField,
    SelectInput,
    SimpleForm,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin';
import { Grid } from "@material-ui/core";
import {ProjectStatusIcon} from './ProjectStatusIcon';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = projects => {
    const csv = convertToCSV({
        data: projects,
        fields: [
            '_id',
            'status',
            'contestantName',
            'projectTitle',
            'creationDate',
            'projectCategory',
            'contestantStreet',
            'contestantZipcode',
            'contestantCity',
            'author',
            'referrer',
            'voteCount',
            'voteCountFinale'
            ]
    });
    downloadCSV(csv, 'projects'); // download as 'posts.csv` file
}

const choices = [
    {id: 0, name: 'abgelehnt'},
    {id: 1, name: 'wartend'},
    {id: 2, name: 'freigegeben'}
];

const choicesCategory = [
    {name: '', id: ''},
];

// Transform API response for logo to match react-admin expectations (https://github.com/marmelab/react-admin/issues/2077#issuecomment-516821629)
function formatLogo(value) {
    if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
        return { url: value };
    } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
        return value;
    }
}

const ProjectsPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

export const ProjectList = props => (
    <List
        {...props}
        exporter={exporter}
        sort={{ field: 'creationDate', order: 'DESC' }}
        pagination={<ProjectsPagination />}
        perPage={200}>
        <Datagrid optimized>
            <ProjectStatusIcon label="Status" source="status"/>
            <TextField label="Teilnehmername" source="contestantName"/>
            <TextField label="Projekttitel" source="projectTitle"/>
            <TextField label="Stimmenzahl (Q)" source="voteCount"/>
            <TextField label="Stimmenzahl (F)" source="voteCountFinale"/>
            <DateField label="Bewerbungsdatum" source="creationDate"/>
            <ReferenceField
                label="Ansprechpartner"
                source="author"
                reference="users"
                linkType="show">
                <FunctionField render={author => `${author.salutation} ${author.firstname} ${author.lastname}`}/>
            </ReferenceField>
            <ReferenceField
                label="Kontakt"
                source="author"
                reference="users">
                <EmailField style={{fontSize: '1rem'}} source="email"/>
            </ReferenceField>
            <EditButton record={props._id} basePath={"/projects"}/>
        </Datagrid>
    </List>
);

export const ProjectEdit = props => (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="Teilnehmerdaten">
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <h1>Teilnehmerdaten</h1>
                    </Grid>
                    <Grid item md={6} lg={8}>
                        <SelectInput fullWidth source="status" choices={choices}/>
                        <TextInput fullWidth label="Teilnehmername" source="contestantName"/>
                        <TextInput fullWidth label="Projekttitel" source="projectTitle"/>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <TextInput fullWidth label="PLZ" source="contestantZipcode"/>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextInput fullWidth label="Ort" source="contestantCity"/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <h1>Profildaten</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput multiline fullWidth label="Antwort auf die 1. Frage" source="contestantAnswer1"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput multiline fullWidth label="Antwort auf die 2. Frage" source="contestantAnswer2"/>
                    </Grid>
                </Grid>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const ProjectCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <Grid container>
                <Grid item xs={12}>
                    <SelectInput fullWidth source="status" choices={choices}/>
                </Grid>
                <Grid item md={6}>
                    <TextInput fullWidth label="Teilnehmername" source="contestantName"/>
                </Grid>
                <Grid item md={6}>
                    <TextInput fullWidth label="Projekttitel" source="projectTitle"/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput multiline label="Antwort auf die 1. Frage" source="contestantAnswer1"/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput multiline label="Antwort auf die 2. Frage" source="contestantAnswer2"/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
